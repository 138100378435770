import "./global.css?modules=false";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { ContentClientJourney } from "@teppixdigital/journey-content-client";

export const Root = () => {
  return <ContentClientJourney />;
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
